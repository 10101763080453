<template>
    <div class="container">
        <div class="section">
            <h1 class="title">COVID-19 Announcements</h1>
            <p> 
                This Workshop cycle presents some unique challenges as we navigate the adversities presented
                by COVID-19. The Flight Software Workshop Organizing Committee has been monitoring the
                pandemic and, out of caution, we delayed the event significantly from the original dates. We
                believe that this additional time allows us to better understand and react to the situation.
            </p>
            <br>
            <div  v-for="covid in covids" v-bind:key="covid.date">
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <div class="title is-6" >
                                {{covid.date_str}}
                                </div>
                                <div class="content">
                                    {{covid.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
        <div class="section">
            <h1 class="title">Contact Us</h1>
            <p> For any questions please contact:  
                <a style="color:blue" href="mailto:info@flightsoftware.org?">info@flightsoftware.org</a>
            </p>
        </div>
    </div>
</template>

<script>
import covids from "@/covid.js";
import _ from "lodash";

export default {
    data() {
        return {
            covids: _.sortBy(_.filter(covids, "date")),
        };
    }
};
</script>

<style lang="scss" scoped>
.has-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>