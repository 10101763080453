<template>
    <div class="Forms">
        <Forms />
    </div>
</template>


<script>
import Forms from "@/components/Forms.vue";
export default {
    components: {
        Forms,
    }
}
</script>