const covids = [
    {
        date: Date.parse("September 8, 2020"),
        date_str: "September 8th, 2020",
        content:
            "We are excited to announce that as a result of our decision to go Virtual for the 2021 FSW Workshop, we will be hosting the workshop from February 8th to the 11th of 2021 FREE of charge! \
            Seeing as we will not be needing a venue and have no other costs associated with an in-person workshop, this year the presentations will be brought to you without cost. \
            Please help us make this year's workshop even better by recommending 2021 Workshop Themes, you can find the form under the 2021 Workshop page.\
            We look forward to virtually seeing you there!",
    },
    {
        date: Date.parse("July 26, 2020"),
        date_str: "July 26, 2020 - COVID19",
        content:
            "In light of recent events, the FSW Workshop 2021 Event has gone Virtual! To ensure the safety of our staff and attendees we have decided to make next year's workshop a virtual event. \
            As we go forth please be on the look out for more information on the event and an upcoming 'Save-the-date' email. Thank you for your understanding. Looking forward \
            to seeing you at the 2021 FSW Workshop!",
    },
    {
        date: Date.parse("June 1, 2020"),
        date_str: "June 1, 2020 - COVID19",
        content:
            "The Workshop is currently scheduled for July 19 - 22, 2021. As we move forward with the planning of the Workshop, please know that it is \
            our top priority to proceed responsibly and minimize risk; if necessary, we may \
            delay the dates further or consider virtual options.",
    },
    {
        date: Date.parse("April 8, 2020"),
        date_str: "April 8, 2020 - COVID19",
        content:
            "The FSW Workshop Planning Committe is closely monitoring the current events pertaining to COVID-19. \
            To ensure the safety of our attendees and sponsors, we have decided to postpone the FSW Workshop 2021 \
            to the summer of 2021. Thank you, and stay safe!",
    },
]

export default covids;