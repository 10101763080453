<template>
    <div class="Covid">
        <Covid />
    </div>
</template>


<script>
import Covid from "@/components/Covid.vue";
export default {
    components: {
        Covid,
    }
}
</script>