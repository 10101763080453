<template>
    <div class="container">
        <div class="section">
            <h1 class="title">FSW Workshop Forms</h1>
        </div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfHNU0r65hB5jY6hXOWkDrNwCsOILkqUi3PPXTnwG44WzDe_Q/viewform?embedded=true" width="900" height="2903" frameborder="0" marginheight="0" marginwidth="0">
            Loading…
        </iframe>
        <div class="section">
            <h1 class="title">Contact Us</h1>
            <p> For any questions please contact:  
                <a style="color:blue" href="mailto:info@flightsoftware.org?">info@flightsoftware.org</a>
            </p>
        </div>
    </div>
</template>

<script>
import covids from "@/covid.js";
import _ from "lodash";

export default {
    data() {
        return {
            covids: _.sortBy(_.filter(covids, "date")),
        };
    }
};
</script>

<style lang="scss" scoped>
.has-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>